
import { ref, computed, watch } from 'vue';
import { isMobile, moment, prompt, showResults } from "@/plugins/interfaceControler";
import { state} from '@/plugins/authService';
import {selectedCabinet}  from "@/plugins/cabinetControler";
import {parseBarcode} from './BarcodeParser';
import config from "@/config";

export const showScan = computed({
  get () {
    return state.value.showScan
  },
  set (value) {
    state.value.showScan = value
  }
})
export const scanType = ref('order') //return retour
export const scannerResult = ref()
export const scanning = ref(false); 
export const manualMode = ref(false); 
export const scannerCode = ref();
export const scanActive = ref(showScan.value)



export function hideScan(){
  showScan.value = false
}

export const windowFocus  = ref(true) //isMobile.value
export let fnResult = (result:any)=>{console.log('scannerResult1',result)}
export function setScannerWatch(fn:any){
  if (fn) fnResult = fn
}
export function resetScan(){
  fnResult = (result:any)=>{console.log('scannerResult2',result)}
}
export const sendKeys:any = ref([])
let code = ""
function handleResize(){
    //isMobile.value = Math.min(window.screen.width, window.screen.height) < 768
  }
handleResize()
watch(showScan,(value)=>{
  if (value) {
    console.log('start')
    startScan()
  }else{
    console.log('stop')
    stopScan()
  }
})
if (showScan.value) startScan()
function handleBlur(event:any) { 
    windowFocus.value = false
    scannerResult.value = null
    code = ""
    window.removeEventListener('blur',handleBlur);
    window.addEventListener('focus', handleFocus);
  }
  
  export function startScan(){
    stopScan()
    window.addEventListener('keydown', handleKeydown);
    window.addEventListener("resize", handleResize);
    console.log('scanner ready')
    scanActive.value=true
    
    if (!isMobile.value){
      window.addEventListener('focus', handleFocus);
    }
  }
  export function stopScan(){
    window.removeEventListener('keydown',handleKeydown);
    window.removeEventListener("resize", handleResize);
    window.removeEventListener('focus', handleFocus);
    scanActive.value=false
  }
  function handleFocus(event:any){
    windowFocus.value = true
    window.addEventListener('blur', handleBlur);
    window.removeEventListener('focus',handleFocus);
  }

  document.onkeydown = function (e) {

    /// check ctrl + f key
    if (e.ctrlKey === true && e.keyCode === 70/*65*/) {
        e.preventDefault();
        showMainSearch(true)
  
        return false;
    }
  }

  export function showMainSearch(select=false){
    hideScan()
    setTimeout(()=>{
          const obj: any =document?.getElementById('main_search')?.childNodes[0]
          if (!obj) return false
          obj.focus()
          if (select) obj.select()
            showResults.value = true
    },200)
  }


  let timeoutHandler: any
  function handleKeydown(event:any){
    const allowed = [13,189,191]
    if (!allowed.includes(event.keyCode) && (event.keyCode < 48 || (event.keyCode > 57 && event.keyCode < 65) || event.keyCode > 90)) return false
    const keyValue:string = event.key;
    const codeValue:string = event.code;
    //console.log(event)
    sendKeys.value.push(codeValue + ' => ' + keyValue , code)
    if (timeoutHandler) clearTimeout(timeoutHandler)
    timeoutHandler = setTimeout(() => {
      setBarcode(code)
      code = ''
    }, 50)
    //console.log("codeValue: " + code,keyValue);
    if (keyValue=='Enter' || keyValue == 'NumpadEnter'){
      setBarcode(code)
      return code = ""
    }
    code += keyValue
    //console.log("codeValue: " + code);
  }

  export function scanCode(stock:any,readable=false) {
    if (!stock) return ''
    if (stock.x_active_stock) {
      const arr = [stock.x_barcode || stock.x_default_code]
      if (stock.x_batch || stock.x_tht){ //GS1
        arr.unshift(readable ? '(01)': '01')
        if (stock.x_tht) {
          arr.push(readable ? '(17)':'17')
          arr.push(moment(stock.x_tht,'YYYY-MM-DD').format('YYMMDD'))
        }
        if (stock.x_batch) {
          if (stock.x_batch.length==6) arr.push(readable ? '(10)': '10')
          if (stock.x_batch.length==8) arr.push(readable ? '(21)': '21')
          arr.push(stock.x_batch)
        }
      }
      return arr.join('')
    }
    return cabinetCode(stock)
  }
 export function cabinetCode(stock:any){
  if (!stock || !stock.x_shelf) return 
  let shelf:any = stock.x_shelf
  if (stock.x_note) shelf = stock.x_note
  if (stock.x_shelf_location) shelf = stock.x_shelf_location
  if (stock.x_cabinet_code) return [stock.x_cabinet_code,shelf.toString().padStart(3, '0'),stock.x_bin.toString().padStart(3, '0')].join('*')
  if (selectedCabinet.value) return [selectedCabinet.value.cabinet_code,shelf.toString().padStart(3, '0'),stock.x_bin.toString().padStart(3, '0')].join('*')
  return [stock.x_shelf.toString().padStart(3, '0'),stock.x_bin.toString().padStart(3, '0')].join('*')
 }

  export function manualCode(){
    prompt("Scancode", "Geef handmatig de code in", "[KASTCODE]*[001]*[001]", "code",function ({description}:any){
      setBarcode(description.toUpperCase())
    })
  }
  let prefCode = ''
  export function setBarcode(code:string, fn?:Function){
    if (!code || code.length < 7) return false
    if (prefCode==code) return false
    //prefCode=code
    scannerResult.value = null
    if (fn) setScannerWatch(fn)
    code = code.replace(/[^A_Za-z0-9-*/]/gi, '');//.replace(/(\r\n|\n|\r|(|))/gm, "")
    sendKeys.value.push("setBarcode: " + code)
     const data = parseBarcodeItem(code)
     
    if (data) { //Success
      scannerResult.value = data
      if (fnResult) fnResult(scannerResult.value)
      sendKeys.value.push('result => ' + JSON.stringify(data))
      return scannerResult.value
    } else {
      scannerResult.value = {_type: 'ERROR', _value: code, message: 'Ongeldige code: ' + code, display:false}
      if (fnResult) fnResult(scannerResult.value)
        scannerResult.value = null
      return false
    }
  }

  export function parseBarcodeItem(code:string){
    code = code.replace(/[^A_Za-z0-9-*/]/gi, '');
    let data:any = {}
    let found = false
    config.scancodes.map((s:any)=>{
      const regex = new RegExp(s.regexp);
      if (!found && regex.test(code)) {
        if (s.field) data[s.field] = code
        if (s.prefix) data = parsePrefix(s, code)
        if (s.split) data = parseSplit(s, code)
        data._type = s.name
        data._value = code
        found = true
      } 
    })
    if (data._type) return data
    return false
  }
  function parseSplit(s:any, code:string){
    const data:any = {}
    const arr = code.split(s.split)
    s.parts.map((part:any)=>{
      data[part.field] = formatValue(part,arr[part.index])
    })
    return data
  }
  
  function parsePrefix(s:any, code:string){
    let result:any
    try{
      result =  parseBarcode(code)
    }catch(e){
      return {value: code} 
    }
    const data:any = {name: result.codeName}
    result.parsedCodeItems.map((item:any)=>{
      const part:any = s.parts.filter((p:any)=>p.prefix ==item.ai)[0]
      if (part) data[part.field] = formatValue(part,item.data)
    })
    return data
  }
function formatValue(part:any,value:string){
  if (part.type=='date') return moment(value,'YYMMDD').format('YYYY-MM-DD')
  if (part.type=='number') return parseInt(value)
  return value
}

//setBarcode('8712345678906')
//setBarcode('87123456')
//setBarcode('01054100131110091723052710ABC1232112345678')
//setBarcode('99sd0sd9')
//setBarcode('L33001*001*001')